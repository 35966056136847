//Hide filter button in my customers datagrid
.my-customers-datagrid .datagrid-filter-toggle {
  display: none;
}

//Font size for pagination footer text in my customers
#customer-number-selection-label {
  font-size: 0.6rem;
  margin: 0;
}
