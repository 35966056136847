@use "variables/breakpoints";
@use "variables/colors";
@use "variables/buttons";

/*
    There are 4 main type of buttons:
    1. outline: id37-brand-color border, white background-color, black text-color
    2. outline: gray border, white background-color, gray text-color
    3. primary: id37-brand-color background-color, white text-color
    4. secondary: gray background-color, black text-color
 */

button, .id37-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: buttons.$id37-btn-font-size;
  font-weight: buttons.$id37-btn-font-weight;
  line-height: buttons.$id37-btn-line-height;
  background-color: buttons.$id37-btn-background-color;
  color: buttons.$id37-btn-text-color;
  border: buttons.$id37-btn-border-size solid transparent;
  border-radius: 0;
  letter-spacing: 0;
  margin: buttons.$id37-btn-margin;
  padding: buttons.$id37-btn-padding;
  word-break: break-word;
  white-space: normal;
  max-width: unset;
  max-height: unset;
  width: auto;
  height: auto;
  cursor: pointer;

  &:disabled {
    pointer-events: none;
  }

  &:hover:enabled {
    border-color: colors.$id37-dark-grey;
  }

  &.id37-btn-outline {
    border: buttons.$id37-btn-border-size solid colors.$id37-brand-color;

    &:hover:enabled {
      border-color: buttons.$id37-btn-outline-border-hover-color;
    }

    &.id37-btn-disabled, &:disabled {
      color: buttons.$id37-btn-outline-disabled-text-color;
      border-color: buttons.$id37-btn-outline-disabled-border-color;
    }
  }

  &.id37-btn-outline-secondary {
    border: buttons.$id37-btn-border-size solid buttons.$id37-btn-outline-secondary-border-color;
    color: buttons.$id37-btn-outline-secondary-text-color;

    &:hover:enabled {
      border-color: buttons.$id37-btn-outline-secondary-hover-border-color;
    }

    &.id37-btn-disabled, &:disabled {
      color: buttons.$id37-btn-outline-disabled-text-color;
      border-color: buttons.$id37-btn-outline-disabled-border-color;
    }
  }

  &.id37-btn-primary {
    background-color: buttons.$id37-primary-btn-background-color;
    color: buttons.$id37-primary-btn-text-color;

    &.id37-btn-disabled, &:disabled {
      background-color: buttons.$id37-primary-btn-disabled-background-color;
      color: buttons.$id37-primary-btn-disabled-text-color;
    }

    &:hover:enabled {
      background-color: buttons.$id37-primary-btn-hover-color;
      border-color: buttons.$id37-primary-btn-hover-color;
    }

    &:active:enabled {
      background-color: buttons.$id37-primary-btn-active-color;
    }

    &.id37-btn-transparent {
      border-color: transparent;
      color: colors.$id37-brand-color;
      background-color: colors.$id37-background-color;

      &.id37-btn-disabled, &:disabled {
        color: colors.$id37-medium-grey-text-color;
      }

      &:hover:enabled {
        background-color: colors.$id37-background-color;
      }

      &:active:enabled {
        background-color: colors.$id37-background-color;
      }
    }
  }

  &.id37-btn-secondary {
    background-color: buttons.$id37-secondary-btn-background-color;
    color: buttons.$id37-secondary-btn-text-color;
    // border-color: buttons.$id37-secondary-btn-border-color;

    &.id37-btn-disabled, &:disabled {
      background-color: buttons.$id37-secondary-btn-disabled-background-color;
      color: buttons.$id37-secondary-btn-disabled-text-color;
      // border-color: buttons.$id37-secondary-btn-disabled-border-color;
    }

    &:hover:enabled {
      background-color: buttons.$id37-secondary-btn-hover-color;
      border-color: buttons.$id37-secondary-btn-hover-color;
    }

    &:active:enabled {
      background-color: buttons.$id37-secondary-btn-active-color;
    }

    &.id37-btn-transparent {
      border-color: transparent;
      color: buttons.$id37-secondary-btn-text-color;
      background-color: colors.$id37-background-color;

      &.id37-btn-disabled, &:disabled {
        color: colors.$id37-medium-grey-text-color;
      }
    }
  }

  &.id37-btn-bw {
    background-color: colors.$id37-grey-0;
    color: colors.$id37-dark-grey;
    border-color: transparent;

    &.selected {
      background-color: colors.$id37-dark-grey;
      color: colors.$id37-grey-0;
      border-color: transparent;
    }

    &.id37-btn-disabled, &:disabled {
      background-color: colors.$id37-grey-0;
      color: colors.$id37-medium-grey;
      border-color: transparent;
    }

    &:hover {
      border-color: colors.$id37-dark-grey;
    }
  }

  &.id37-btn-warning {
    background-color: buttons.$id37-warning-btn-background-color;
    color: buttons.$id37-warning-btn-text-color;
    border-color: buttons.$id37-warning-btn-border-color;
  }

  &.id37-btn-danger {
    background-color: buttons.$id37-danger-btn-background-color;
    color: buttons.$id37-danger-btn-text-color;
    border-color: buttons.$id37-danger-btn-border-color;
  }

  &.id37-btn-sm {
    font-size: buttons.$id37-btn-sm-font-size;
    line-height: buttons.$id37-btn-sm-line-height;
    padding: buttons.$id37-btn-sm-padding;
  }

  &.id37-btn-flat {
    padding: buttons.$id37-btn-flat-padding;
    @media screen and (max-width: breakpoints.$sm-break) {
      & {
        padding: buttons.$id37-btn-flat-padding-mobile;
      }
    }
  }

  &.id37-btn-rounded-1 {
    border-radius: 4px;
  }

  &.id37-btn-rounded-2 {
    border-radius: 24px;
  }

  &.id37-btn-full-width {
    width: 100%;
  }

  &.id37-btn-bold {
    font-weight: bold;
  }

  &.id37-btn-hover-animation {
    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: scale(1.05);
    }
  }

  text-transform: none;
  outline: none;
  box-shadow: none;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  img {
    width: 20px;
    height: 20px;
    float: left;
  }

  img + span {
    margin-left: 8px;
  }
}

.id37-btn-row {
  button.id37-btn:last-child, .id37-btn:last-child {
    margin-right: 0;
  }
  .id37-btn-outline-secondary {
    margin-right: 16px;
  }
}
